import request from "@/utils/request";
import { ref, Ref, reactive, defineAsyncComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { copyFrom, copy } from "./common";

export function useQuery(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  function query() {
    isLoading.value = true;
    request
      .get(`/thingApply/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}

export function useQueryActionHistory(id = null): Array<any> {
  const result = ref([]);
  function query(_id) {
    const idValue = _id !== undefined ? _id : id?.value;
    if (idValue) {
      request.get(`/thingApply/logHistory/${idValue}`).then((res) => {
        result.value = res;
      });
    }
  }
  return [result, query];
}

export function useThingApply(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);

  const empty = {
    id: 0,
    applyCode: null,
    applyType: null,
    applyFileTitle: null,
    doc_Type: null,
    doc_Year: null,
    doc_Serial_No: null,
    applyPerson: null,
    doPerson: null,
    concactPhone: null,
    projectName: null,
    heritageUnitId: null,
    heritageUnitName: null,
    heritageClass: null,
    heritageLevel: null,
    provId: 22,
    cityId: null,
    countryId: null,
    isRevolution: null,
    isWorldHeritage: null,
    planName: null,
    planWriter: null,
    applyUnit: null,
    projectType1: null,

    projectType1ObjName: null,
    projectType1Area: null,
    projectType1Amount: null,
    projectType1FundSource: null,
    projectType1ProjectManger: null,

    projectType2: [],
    projectType2Compose: [],
    projectType2Area: null,
    projectType2Amount: null,
    otherDesc: null,
    attachList: [],
    status: 0,
    createTime: null,
    updateTime: null,
    createUser: 0,
  };

  const result = reactive(copyFrom(empty));

  function remove(idValue: number): void {
    isLoading.value = true;
    return request
      .delete(`/thingApply/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v > 0) {
      return request
        .get(`/thingApply/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          // console.log(
          //   Object.assign(result, copy(empty), copy(res)),
          //   copy(empty),
          //   copy(res)
          // );
          copyFrom(result, empty, res);

          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      //result.attachList.length = 0;
      // result.attachList = [];
      copyFrom(result, empty);
      //console.log(result);
      return Promise.resolve(result);
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value > 0) {
      ps = request
        .put(`/thingApply/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/thingApply`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  function review(id, reviewResult) {
    isLoading.value = true;

    return request
      .put(`/thingApply/review/${id}`, reviewResult)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, find, save, remove, review];
}

export const useThingApplyAction = (): Array<any> => {
  const isLoading = ref(false);
  const cancel = ref(null);
  const back = (id, reason) => {
    isLoading.value = true;
    return request({
      headers: {
        "Content-Type": "application/json",
      },
      method: "put",
      url: `/thingApply/back/${id}`,
      data: JSON.stringify(reason),
    })
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const remove = (id) => {
    return request
      .delete(`/thingApply/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  return [isLoading, back, remove];
};
